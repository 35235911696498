.App {
  text-align: center;
}
@media only screen and (max-width: 305px) {
  #root {
    width: -webkit-min-content;
    width: min-content;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#contentTabsHeader div[role="tablist"] {
  justify-content: center;
}

#contentTabsHeader div[role="tablist"]::before {
  content: "";
  border-radius: 2px;
  bottom: 0;
  margin: 0;
  position: absolute;
  width: inherit;
  left: 8px;
  right: 8px;
  background-color: var(--border-neutral, "transparent");
  height: 2px;
}

#contentTabsHeader div.tabItem::before {
  content: "";
  border-radius: 2px;
  bottom: 0;
  margin: 0;
  position: absolute;
  width: inherit;
  left: 8px;
  right: 8px;
  background-color: var(--border-neutral, "transparent");
  height: 2px;
}

#contentTabsHeader div.tabItem[aria-selected="true"]::after {
  content: "";
  border-radius: 0px;
  bottom: 0;
  margin: 0;
  position: absolute;
  width: inherit;
  left: 0px;
  right: 0px;
  border-bottom: 4px solid var(--text-selected, #fff);
  height: 0;
}

#contentTabsHeader div.tabItem {
  justify-content: center;
  font-weight: 400;
}

#contentTabsHeader div.tabItem:hover {
  background-color: #152e58;
}
div.divperfil > *:last-child {
  margin: 0px 6px;
  display: inline-block;
  vertical-align: middle;
}
div.divperfil > span {
  vertical-align: middle;
}
div.divperfil > button {
  vertical-align: middle;
  color: #fff !important;
  font-size: 14px;
}
div.divperfil > button:hover {
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  div.divperfil {
    margin-bottom: 15px;
  }
}

#contentTabsHeader div.tabItem[title="Proyectos"] > div {
  display: inline-block;
}

div.contentTabla tbody {
  border-bottom: 2px solid var(--border-neutral, #dfe1e6);
}
div.contentBuscador > div {
  display: inline-block;
  margin-right: 10px;
}

button.btn_reenviar {
  background-color: #2eb85c;
  color: #fff !important;
}
button.btn_reenviar:hover {
  background-color: #36ce69;
  color: #fff !important;
}
button.btn_soporte {
  background-color: #39f;
  color: #fff !important;
}
button.btn_soporte:hover {
  background-color: #0d86ff;
  color: #fff !important;
}
div.descProyecto {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
p.descProyecto {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.titulo_pr {
  font-size: 16px;
  font-weight: 400;
  color: #6b778c;
}

.videoPlayer {
  width: 100% !important;
  height: auto !important;
}
body button {
  font-size: 14px !important;
  font-weight: 400 !important;
}
#menuPanelAdmin button {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.btn_sel {
  background-color: #f4f5f7 !important;
  color: #0052cc !important;
}

.labelArchivos {
  cursor: pointer;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #e4e4e4;
  font-size: 14px;
}

.labelArchivosDelete {
  cursor: pointer;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #de350b;
  margin-left: 10px;
  font-size: 14px;
  color: white;
}

.selec_registro {
  font-size: 14px;
}

.selec_registro > div > div:nth-child(1) > div {
  line-height: 22px !important;
}

.banners > div:nth-child(2) > div {
  background-color: #00875a !important;
}
.banners > div:nth-child(2) > div span {
  fill: #00875a !important;
}

.content_document {
  font-size: 14px;
  padding: 15px;
  margin: 5px 0px;
  border: 1px solid #dce0e5;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-box-shadow: 1px 1px 5px 1px rgb(227, 227, 227);
  -moz-box-shadow: 1px 1px 5px 1px rgb(227, 227, 227);
  box-shadow: 1px 1px 5px 1px rgb(227, 227, 227);
}
.nombre_doc {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#menuResponsive {
  display: none;
  cursor: pointer;
}
.contentOpcion {
  margin-top: 10px;
  border: 1px solid #e1e1e1;
}
.tituloOpcion {
  padding: 10px;
  background-color: #ebf0f6;
  box-sizing: border-box;
}
.tituloOpcion > span:nth-child(2) {
  float: right;
}
.infoOpcion {
  display: none;
  padding: 5px 15px 15px;
}
.infoOpcion_visible {
  display: block;
  padding: 5px 15px 15px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
.span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.DayPicker {
  display: inline-block;
  font-size: 1.8rem !important;
}
.nAgenda .DayPicker {
  display: inline-block;
  font-size: 1.3rem !important;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #0052cc !important;
  color: #f0f8ff;
}
.nAgenda
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #0052cc !important;
  color: #f0f8ff;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 18% !important;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.nAgenda .DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 18% !important;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

#divCalendario > .DayPicker--interactionDisabled {
  font-size: 100% !important;
}

.DayPicker-Months {
  box-sizing: border-box;
}
.DayPicker-Month {
  box-sizing: border-box;
}

.cAgenda {
  margin-bottom: 15px;
}
#tabla_club > div:nth-child(1) {
  overflow-x: auto;
  width: 100%;
}
.contentTabla > div:nth-child(2) {
  overflow-x: auto;
  width: 100%;
}
.cProyecto #divCalendario {
  padding: 0px !important;
}

.cProyecto #divCalendario .DayPicker-Month,
.cProyecto #divCalendario .DayPicker-NavBar {
  font-size: 13px !important;
}

.contentTagForo > span {
  height: 17px;
}
.contentTagForo > span > span {
  font-size: 12px;
}

.contentAlertas > span:nth-child(1) {
  margin-right: 7px;
}
.contentAlertas > span:nth-child(2) {
  position: absolute;
  right: 0px;
  top: -7px;
}
.contentAlertas > span:nth-child(2) > span {
  margin: 0px;
}
.contentAlertas > div {
  width: 420px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  box-sizing: border-box;
  padding: 15px;
}
.dialogAlertas {
  cursor: default;
}
.alertaLeida {
  background-color: #f1f1f1 !important;
}
.conversejs .fa-paperclip {
  display: none !important;
}
.conversejs .fa-user-plus {
  display: none !important;
}
.conversejs .fa-plus {
  display: none !important;
}
.conversejs .fa-cog {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1420px) {
  #divCalendario {
    padding: 0px !important;
  }
  #contentTabsHeader div.tabItem {
    font-size: 12px !important;
    padding: 8px 10px !important;
  }
  #img_header {
    width: 150px !important;
  }
  #img_header > img {
    width: 100%;
  }
  #contentTabsHeader {
    width: calc(100% - 340px) !important;
  }
  #contentTabsHeader div.tabItem > span:nth-child(1) {
    width: 18px !important;
  }
}
@media (max-width: 1355px) {
  .cProyecto #divCalendario .DayPicker-Month,
  .cProyecto #divCalendario .DayPicker-NavBar {
    font-size: 12px !important;
  }
}
@media (max-width: 1290px) {
  .DayPicker-Month,
  .DayPicker-NavBar {
    font-size: 16px !important;
  }
  .nAgenda .DayPicker-Month,
  .nAgenda .DayPicker-NavBar {
    font-size: 14px !important;
  }
  #divCalendario .DayPicker-Month,
  #divCalendario .DayPicker-NavBar {
    font-size: 13px !important;
  }
}
@media (max-width: 1150px) {
  #contentTabsHeader {
    display: none !important;
  }
  #menuResponsive {
    display: inline-block;
    color: #fff;
    float: right;
    margin-top: 5px;
  }
  #img_header {
    width: calc(100% - 200px) !important;
  }
  #img_header > img {
    width: auto;
  }
}
@media (max-width: 992px) {
  .DayPicker-Month,
  .DayPicker-NavBar {
    font-size: 1.8rem !important;
  }
  .nAgenda .DayPicker-Month,
  .nAgenda .DayPicker-NavBar {
    font-size: 1.3rem !important;
  }

  #divCalendario .DayPicker-Month,
  #divCalendario .DayPicker-NavBar {
    font-size: 100% !important;
  }
  div.contentBuscador {
    display: block !important;
  }
  div.contentBuscador > div {
    display: block !important;
    margin-right: 0px !important;
  }
  div.contentBuscador input {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  #menuPanelAdmin {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    display: block !important;
  }
  #panelAdmin {
    width: 100% !important;
    height: auto !important;
    display: block !important;
    max-height: unset !important;
  }
  .cProyecto #divCalendario .DayPicker-Month,
  .cProyecto #divCalendario .DayPicker-NavBar {
    font-size: 16px !important;
  }
  .conversejs {
    left: unset !important;
    right: unset !important;
  }
  body .conversejs .converse-chatboxes #controlbox .box-flyout {
    width: 86% !important
  }
  body .conversejs .converse-chatboxes .chatbox .box-flyout {
    margin-left: 55px;
    width: 86% !important;
  }
  body .conversejs converse-chats:not(.converse-embedded) .chatbox {
    width: 100vw;
  }
}

@media (max-width: 600px) {
  #img_header {
    width: 100% !important;
    padding: 10px !important;
  }
  #img_header > img {
    width: auto;
  }
  #contentTabsHeader {
    display: none !important;
  }
  #menuResponsive {
    display: block;
    float: right;
    color: #fff;
    margin-top: 0px;
  }
  .DayPicker-Month,
  .DayPicker-NavBar {
    font-size: 16px !important;
  }
  .divperfil {
    width: 100% !important;
  }
  .contentAlertas > div {
    max-height: calc(100vh - 100px);
  }
}

@media (max-width: 430px) {
  .contentAlertas > div {
    width: 95%;
  }
}

@media only screen and (max-width: 1208px) {
  .btnAdmin {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1068px) {
  .btnAdmin2 {
    margin: 30px 0;
  }
  .contentBuscador {
    width: 100%;
  }
  .seachDocumentacion {
    width: 100%;
  }
}

@media only screen and (max-width: 1599px) {
  .btnComentarPublicacion {
    margin-left: 35px !important;
    margin-top: 15px;
  }
}

.btnResp {
  margin-top: 15px;
  text-align: right;
}

.comentariosComponents > div,
.comentariosSubcomponents > div {
  padding-left: 0px !important;
}

.comentariosComponents.Margin .comentariosSubcomponents:first-child {
  padding-left: 30px !important;
}

.comentariosComponents.noMargin .comentariosSubcomponents:first-child {
  padding-left: 0px !important;
}

.deleteComentario {
  margin: 0px;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 12px;
  color: #bd0000;
  float: right;
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .deleteComentario {
    width: 100% !important;
    margin-left: 10px !important;
    float: none;
  }
  .pComentario {
    margin-top: initial;
  }
}
